import React from 'react';

import UFBenefits from '../../../assets/images/uf-why/uf-benefits.jpg';
import UFBenefits2x from '../../../assets/images/uf-why/uf-benefits@2x.jpg';
import UFBenefits_s from '../../../assets/images/uf-why/uf-benefits_s.jpg';
import UFBenefits2x_s from '../../../assets/images/uf-why/uf-benefits@2x_s.jpg';

import UFBenefitsWebp from '../../../assets/images/uf-why/uf-benefits.webp';
import UFBenefitsWebp2x from '../../../assets/images/uf-why/uf-benefits@2x.webp';
import UFBenefitsWebp_s from '../../../assets/images/uf-why/uf-benefits_s.webp';
import UFBenefitsWebp2x_s from '../../../assets/images/uf-why/uf-benefits@2x_s.webp';

function UfWhatAreTheBenefits() {
  return (
    <>
      <section className=" relative">
        <div className="uf-benefits-container relative">
          <div className="benefits-container-main flex justify-end">
            <div className="uf-benefits-container-content flex flex-col items-center">
              <div>
                  <svg
                id="Component_61_1"
                data-name="Component 61 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="344.906"
                height="201"
                viewBox="0 0 344.906 201"
              >
                <text
                  id="_7_out_of_10_women_with_moderate_to_severe_menstrual_pain"
                  data-name="7 out of 10 women* with moderate to severe menstrual pain"
                  transform="translate(64.563 76.5)"
                  fill="#fff"
                  fontSize="35"
                  fontFamily="SourceSansPro-Semibold, Source Sans Pro"
                  fontWeight="600"
                >
                  <tspan x="24.616" y="36">
                    out
                  </tspan>
                  <tspan x="35.221" y="72">
                    of
                  </tspan>
                </text>
                <text
                  id="_7_out_of_10_women_with_moderate_to_severe_menstrual_pain-2"
                  data-name="7 out of 10 women* with moderate to severe menstrual pain"
                  fill="#fff"
                  fontSize="160"
                  fontFamily="Mulish-Black, Mulish"
                  fontWeight="800"
                >
                  <tspan x="0" y="161">
                    7
                  </tspan>
                </text>
                <text
                  id="_7_out_of_10_women_with_moderate_to_severe_menstrual_pain-3"
                  data-name="7 out of 10 women* with moderate to severe menstrual pain"
                  transform="translate(143.906)"
                  fill="#fff"
                  fontSize="160"
                  fontFamily="Mulish-Black, Mulish"
                  fontWeight="800"
                >
                  <tspan x="0" y="161">
                    10
                  </tspan>
                </text>
              </svg>
              </div>
            

              <h2 className="font-mulish text-center font-bold md:text-center">
                women saw a meaningful decrease in period bleeding<sup>†‡</sup>
              </h2>

              <p className="text-center">
                <sup>†</sup>Normal bleeding is defined as 80 mL (about 1/3 cup) or less.
              </p>
              <p className='text-center'>
                <sup>‡</sup>At week 24, compared with 16% of women on placebo. 
              </p>
            </div>
          </div>
          <picture className="absolute top-0">
            <source
              srcSet={`${UFBenefitsWebp2x_s}, ${UFBenefitsWebp2x_s} 2x`}
              media="(max-width: 672px)"
              type="image/webp"
            />

            <source
              srcSet={`${UFBenefits2x_s}, ${UFBenefits2x_s} 2x`}
              media="(max-width: 672px)"
            />

            <source
              srcSet={UFBenefitsWebp2x}
              media="(max-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={UFBenefits2x} media="(max-width: 1152px)" />

            <source
              srcSet={UFBenefitsWebp2x}
              media="(min-width: 1152px)"
              type="image/webp"
            />

            <source srcSet={UFBenefits2x} media="(min-width: 1152px)" />

            <img
              className="w-100 img-fluid"
              src={UFBenefitsWebp2x}
              alt="Actor portrayal of a woman who takes MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) tablets having a conversation with another woman"
              width={720}
              height={626}
              fetchPriority="high"
            />
          </picture>
        </div>
      </section>
    </>
  );
}

export default UfWhatAreTheBenefits;
