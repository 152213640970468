import React from 'react';
// left
import UFWhyBgLeft from '../../../assets/images/uf-why/uf-why-hero-left-bg.jpg';
import UFWhyBgLeft2x from '../../../assets/images/uf-why/uf-why-hero-left-bg@2x.jpg';
import UFWhyBgLeft_s from '../../../assets/images/uf-why/uf-why-hero-left-bg_s.jpg';
import UFWhyBgLeft2x_s from '../../../assets/images/uf-why/uf-why-hero-left-bg@2x_s.jpg';

import UFWhyWebpBgLeft from '../../../assets/images/uf-why/uf-why-hero-left-bg.webp';
import UFWhyWebpBgLeft2x from '../../../assets/images/uf-why/uf-why-hero-left-bg@2x.webp';
import UFWhyWebpBgLeft_s from '../../../assets/images/uf-why/uf-why-hero-left-bg_s.webp';
import UFWhyWebpBgLeft2x_s from '../../../assets/images/uf-why/uf-why-hero-left-bg@2x_s.webp';

//right
import UFWhyBgRight from '../../../assets/images/uf-why/uf-why-hero-right-bg.jpg';
import UFWhyBgRight2x from '../../../assets/images/uf-why/uf-why-hero-right-bg@2x.jpg';
import UFWhyBgRight_s from '../../../assets/images/uf-why/uf-why-hero-right-bg_s.jpg';
import UFWhyBgRight2x_s from '../../../assets/images/uf-why/uf-why-hero-right-bg@2x_s.jpg';

import UFWhyWebpBgRight from '../../../assets/images/uf-why/uf-why-hero-right-bg.webp';
import UFWhyWebpBgRight2x from '../../../assets/images/uf-why/uf-why-hero-right-bg@2x.webp';
import UFWhyWebpBgRight_s from '../../../assets/images/uf-why/uf-why-hero-right-bg_s.webp';
import UFWhyWebpBgRight2x_s from '../../../assets/images/uf-why/uf-why-hero-right-bg@2x_s.webp';

function UfWhyMyfembreeHero() {
  return (
    <section className="uf-why-myfembree-hero relative">
      <div className="uf-why-container mx-auto">
        <div className="text-white-100 flex flex-col items-center justify-between md:flex-row md:items-baseline">
          <h1 className="text-center">
          My bag of extra clothes was a constant reminder of heavy bleeding. 
          </h1>

          <span className="text-center">
          With lighter periods, it’s another thing off my shoulders.
          </span>

          <div className="uf-why-hero-image absolute left-0 top-0 flex flex-col md:flex-row">
            <picture className="">
              <source
                srcSet={`${UFWhyWebpBgLeft2x_s}, ${UFWhyWebpBgLeft2x_s} 2x`}
                media="(max-width: 672px)"
                type="image/webp"
              />

              <source
                srcSet={`${UFWhyBgLeft2x_s}, ${UFWhyBgLeft2x_s} 2x`}
                media="(max-width: 672px)"
              />

              <source
                srcSet={UFWhyWebpBgLeft2x}
                media="(max-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={UFWhyBgLeft2x} media="(max-width: 1152px)" />

              <source
                srcSet={UFWhyWebpBgLeft2x}
                media="(min-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={UFWhyBgLeft2x} media="(min-width: 1152px)" />

              <img
                className="w-100 img-fluid"
                src={UFWhyWebpBgLeft2x}
                alt="Two-panelled actor portrayal of a woman at home, first on the couch  and then the same woman dancing with her kids."
                width={719}
                height={634}
                fetchPriority="high"
              />
            </picture>
            <picture className="">
              <source
                srcSet={`${UFWhyWebpBgRight2x_s}, ${UFWhyWebpBgRight2x_s} 2x`}
                media="(max-width: 672px)"
                type="image/webp"
              />

              <source
                srcSet={`${UFWhyBgRight2x_s}, ${UFWhyBgRight2x_s} 2x`}
                media="(max-width: 672px)"
              />

              <source
                srcSet={UFWhyWebpBgRight2x}
                media="(max-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={UFWhyBgRight2x} media="(max-width: 1152px)" />

              <source
                srcSet={UFWhyWebpBgRight2x}
                media="(min-width: 1152px)"
                type="image/webp"
              />

              <source srcSet={UFWhyBgRight2x} media="(min-width: 1152px)" />

              <img
                className="w-100 img-fluid"
                src={UFWhyWebpBgRight2x}
                alt="Two-panelled actor portrayal of a woman at home, first on the couch  and then the same woman dancing with her kids."
                width={719}
                height={634}
                fetchPriority="high"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UfWhyMyfembreeHero;
