import React from 'react';

import FibroidsImage from '../../../assets/images/uf-why/fibroids-image.png';
import FibroidsImageWebp from '../../../assets/images/uf-why/fibroids-image.webp';

function UfWhatCauses() {
  return (
    <section className="uf-what-causes-endometriosis-pain">
      <div className="container mx-auto">
        <div className="what-causes-endometriosis-pain-content relative mx-auto">
          <div className="">
            <h2 className="font-mulish text-center  font-bold md:text-left ">
              What are uterine fibroids?
            </h2>

            <picture className="relative end-0 mx-auto block lg:absolute">
              <source srcSet={FibroidsImageWebp} type="image/webp" />
              <img
                className="w-100 img-fluid"
                src={FibroidsImage}
                alt="Illustration of a uterus"
                width={356}
                height={300}
                loading="lazy"
              />
            </picture>
          </div>

          <p className="text-grey-100 mx-auto text-center lg:ms-0 lg:text-left">
            Are your periods feeling heavy? Uterine fibroids (also called
            uterine myoma) are a common cause. While it’s not known exactly what
            causes uterine fibroids, they are non-cancerous and develop as
            growths that are found in and around the uterus. The main symptom of
            UF is heavy menstrual bleeding.
          </p>

          <p className="text-grey-100 mx-auto text-center lg:ms-0 lg:text-left">
            Symptoms from fibroids may make dealing with periods challenging,
            such as bleeding for more than 7 days, requiring more than one
            pad/tampon at a time, and feeling dizzy or fatigued due to anemia
            from UF-related heavy menstrual bleeding.
          </p>
        </div>
      </div>
    </section>
  );
}

export default UfWhatCauses;
