import React from 'react';

import DuringPeriodsIcon from '../../../assets/images/during-periods-icon.svg';
import BetweenPeriodsIcon from '../../../assets/images/between-periods-icon.svg';
import DuringSexIcon from '../../../assets/images/during-sex-icon.svg';
import useIsEdge from '../../../util/use-is-edge';

// const searchingList = [
//   {
//     img: DuringPeriodsIcon,
//     imgW: 98,
//     imgH: 77,
//     text: 'during periods',
//     alt: 'Illustration of female body experiencing cramps'
//   },
//   {
//     img: BetweenPeriodsIcon,
//     imgW: 85,
//     imgH: 88,
//     text: 'between periods',
//     alt: 'Calendar icon'
//   },
//   {
//     img: DuringSexIcon,
//     imgW: 85,
//     imgH: 85,
//     text: 'during sex*',
//     alt: 'Intimacy icon'
//   }
// ];

function UfWhyMyFembreeMakes() {
  const isEdge = useIsEdge();

  return (
    <section>
      <div className={`container mx-auto ${isEdge ? 'is-edge-container' : ''}`}>
        <div className="uf-why-card flex flex-col justify-center items-center text-white-100">
          <h2 className="font-mulish font-bold text-center">
            Myfembree makes lighter periods possible
            {/* Searching for <br className="md:hidden" /> relief from your
            endometriosis pain? */}
          </h2>

          <p className="text-center font-semibold">
            Myfembree was studied <span className='inline-block'>and proven</span> effective in two <span className='inline-block'>6-month</span> clinical
            studies involving a total of 768 premenopausal women who had heavy
            period bleeding due to uterine fibroids.
          </p>

          <div className="mid-content flex text-center md:text-right flex-col md:flex-row items-center">
            <p>After taking Myfembree, average <br className='hidden md:block'/> period bleeding went down by</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="374.488"
              height="126.207"
              viewBox="0 0 374.488 126.207"
            >
              <g
                id="Component_61_1"
                data-name="Component 61 – 1"
                transform="translate(0)"
              >
                <text
                  id="Ready_to_start_Myfembree_"
                  data-name="Ready to start Myfembree?"
                  transform="translate(374.488 30)"
                  fill="#fff"
                  font-size="30"
                  font-family="Mulish-Bold, Mulish"
                  font-weight="700"
                >
                  <tspan x="-13.71" y="0">
                    *
                  </tspan>
                </text>
                <g
                  id="Myfembree_KO_UF_84_pad_percent_visual_v2_1"
                  data-name="Myfembree_KO_UF_84 pad_percent_visual_v2 1"
                  transform="translate(-36.275 -43.6)"
                >
                  <g
                    id="Group_4614"
                    data-name="Group 4614"
                    transform="translate(151.85 59.6)"
                  >
                    <path
                      id="Path_2273"
                      data-name="Path 2273"
                      d="M106.127,169.4c-12.965,0-23.095-2.836-30.388-8.1S64.8,148.333,64.8,138.608c0-7.7,2.431-14.181,6.888-19.043s10.129-7.7,17.422-8.509v4.457a26.68,26.68,0,0,1-15.4-9.319,26.09,26.09,0,0,1-6.078-17.422A25.94,25.94,0,0,1,78.171,67.3c6.888-5.267,16.207-7.7,28.362-7.7s21.474,2.431,28.362,7.7,10.534,12.155,10.534,21.474a29.268,29.268,0,0,1-5.672,17.828c-3.647,4.862-8.914,7.7-14.991,8.914v-4.052a26.96,26.96,0,0,1,17.017,8.914c4.457,4.862,6.483,11.345,6.483,18.638,0,9.724-3.647,17.422-10.94,22.69C129.222,166.565,119.093,169.4,106.127,169.4Zm0-21.069c5.267,0,9.724-1.216,12.155-3.241,2.836-2.026,4.052-5.267,4.052-8.914,0-4.052-1.216-6.888-4.052-8.914s-6.888-2.836-12.155-2.836-9.319.81-12.155,2.836-4.052,4.862-4.052,8.914,1.216,6.888,4.052,8.914C96.4,147.522,100.455,148.333,106.127,148.333Zm0-44.974c4.457,0,7.7-1.216,10.129-3.241a10.465,10.465,0,0,0,3.647-8.509,9.973,9.973,0,0,0-4.052-8.1c-2.431-2.026-5.672-3.241-10.129-3.241s-7.7,1.216-10.129,3.241a10.465,10.465,0,0,0-3.647,8.509,10.465,10.465,0,0,0,3.647,8.509A20.227,20.227,0,0,0,106.127,103.359Z"
                      transform="translate(-64.8 -59.6)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2274"
                      data-name="Path 2274"
                      d="M131.058,166.56V148.733H87.3V129.284L135.515,60H157.8v68.069h13.776v20.664H157.8V166.56Zm0-38.9V92.819h4.052l-28.362,41.327v-6.483Z"
                      transform="translate(3.864 -58.379)"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Group_4615"
                    data-name="Group 4615"
                    transform="translate(331.341 60.41)"
                  >
                    <path
                      id="Path_2275"
                      data-name="Path 2275"
                      d="M122.471,91.809a12.329,12.329,0,0,1-9.724-4.457c-2.431-2.836-3.647-6.888-3.647-11.75s1.216-8.914,3.647-11.75,5.672-4.052,9.724-4.052a12.328,12.328,0,0,1,9.724,4.457c2.431,2.836,3.647,6.888,3.647,11.75s-1.216,8.914-3.647,11.75C130.169,90.188,126.928,91.809,122.471,91.809Zm0-6.078a5.083,5.083,0,0,0,4.457-2.431,16.775,16.775,0,0,0,1.621-7.7c0-3.647-.405-6.078-1.621-7.7a5.3,5.3,0,0,0-8.914,0,16.775,16.775,0,0,0-1.621,7.7c0,3.647.405,6.078,1.621,7.7A5.6,5.6,0,0,0,122.471,85.731Zm7.293,27.146h-8.1L152.453,60.61h8.1Zm30.388.405a12.329,12.329,0,0,1-9.724-4.457c-2.431-2.836-3.647-6.888-3.647-11.75s1.216-8.914,3.647-11.75,5.672-4.052,9.724-4.052a12.328,12.328,0,0,1,9.724,4.457c2.431,2.836,3.647,6.888,3.647,11.75s-1.216,8.914-3.647,11.75S164.2,113.283,160.152,113.283Zm0-5.672a5.083,5.083,0,0,0,4.457-2.431,16.776,16.776,0,0,0,1.621-7.7c0-3.647-.405-6.078-1.621-7.7a4.654,4.654,0,0,0-4.457-2.431,5.084,5.084,0,0,0-4.457,2.431,16.775,16.775,0,0,0-1.621,7.7c0,3.647.405,6.078,1.621,7.7A5.084,5.084,0,0,0,160.152,107.61Z"
                      transform="translate(-109.1 -59.8)"
                      fill="#fff"
                    />
                  </g>
                  <path
                    id="Path_2276"
                    data-name="Path 2276"
                    d="M77.7,165.45h0a13.013,13.013,0,0,0,8.914,4.052,11.661,11.661,0,0,0,8.509-3.647l.405-.405,38.9-38.9a11.746,11.746,0,0,0-16.612-16.612L98.367,129.39V71.045a11.345,11.345,0,0,0-22.69,0v58.345L56.23,109.941a11.746,11.746,0,1,0-16.612,16.612"
                    transform="translate(0 0.305)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>

          <small className="text-center block">
            *At week 24, compared with an average 17% reduction in women on
            placebo.
          </small>

          <p className="text-center">
            Myfembree is not a surgery, procedure, injection, <span className='inline-block'>or painkiller.</span> It
            is the first and only FDA-approved once-daily pill to reduce heavy
            menstrual bleeding from uterine fibroids in premenopausal women.
          </p>
        </div>
      </div>
    </section>
  );
}

export default UfWhyMyFembreeMakes;
