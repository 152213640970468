import React from 'react';

import CommonSideEffectsIcon from '../../../assets/images/uf-why/uf-common-side-effect-icon.png';
import SeriousSideEffectsIcon from '../../../assets/images/uf-why/uf-serious-side-effect-icon.png';
import DiscontinuationsSideEffectsIcon from '../../../assets/images/uf-why/uf-discontinuations-icon.png';

function UfPossibleSideEffects() {
  return (
    <section className="possible-side-effects-container" id="side-effects">
      <div className="container mx-auto">
        <h2 className="font-mulish mx-auto">
          What are the possible side effects with Myfembree?
        </h2>

        <p className="mx-auto">
          In clinical trials, the safety of Myfembree was also studied.
        </p>

        <ul className="">
          <li className="mx-auto flex flex-col items-center md:flex-row md:items-start">
            <img
              src={CommonSideEffectsIcon}
              width={96}
              height={92}
              alt=""
              loading="lazy"
            />

            <div>
              <h3 className="font-mulish">The most common side effects </h3>

              <p>
                The most common side effects of taking Myfembree for heavy
                period bleeding due to UF include hot flushes, increased
                sweating, night sweats, abnormal vaginal bleeding (bleeding that
                lasts too long, is too heavy, or is unexpected), hair loss or
                hair thinning, and decreased interest in sex. Always tell your
                doctor if you experience a side effect that bothers you or will
                not go away.
              </p>
            </div>
          </li>

          <li className="mx-auto flex flex-col items-center md:flex-row md:items-start">
            <img
              src={SeriousSideEffectsIcon}
              width={96}
              height={92}
              alt=""
              loading="lazy"
            />

            <div>
              <h3 className="font-mulish">Serious side effects </h3>

              <p>
                Serious side effects were reported in 3.1% of women on Myfembree
                vs 2.3% on placebo. Fibroid expulsion with heavy bleeding,
                fibroid prolapse, gallbladder inflammation, and pelvic pain were
                experienced by one person each across both studies.
              </p>
            </div>
          </li>

          <li className="mx-auto flex flex-col items-center md:flex-row md:items-start">
            <img
              src={DiscontinuationsSideEffectsIcon}
              width={96}
              height={92}
              alt=""
              loading="lazy"
            />

            <div>
              <h3 className="font-mulish">Discontinuations</h3>

              <p>
                In clinical trials, 3.9% of women treated with Myfembree stopped
                taking Myfembree as a result of side effects, compared to 4.3%
                of women in the placebo group. The most common side effect that
                led to discontinuation was uterine bleeding (1.2%), occurring
                usually within the first 3 months of treatment.
              </p>
            </div>
          </li>
        </ul>

        <small className="mx-auto block font-semibold">
          These are not all the possible side effects of Myfembree.
        </small>
      </div>
    </section>
  );
}

export default UfPossibleSideEffects;
